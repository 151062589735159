import React, { useRef, useEffect, useState } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Inject, TimelineViews, Resize, DragAndDrop, TimelineMonth } from '@syncfusion/ej2-react-schedule';
import { extend } from '@syncfusion/ej2-base';
import { useFirestore } from '../contexts/FirestoreContext';
import { db } from '../services/firebase'; // Import Firestore
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import '../styles/App.css';
import Task from '../models/Task';

const CalendarView = ({ projectId }) => {
  const scheduleObj = useRef(null);
  const { tasks } = useFirestore();
  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    if (projectId && tasks) {
      const projectTasks = tasks
        .filter(task => task.project_id === projectId)
        .map(task => ({
          Id: task.gid,
          Subject: task.name,
          StartTime: task.due_date_start ? new Date(task.due_date_start) : new Date(),
          EndTime: task.due_date_end ? new Date(task.due_date_end) : new Date(new Date().setHours(new Date().getHours() + 1)),
          Description: task.notes || ''
        }));

      setCalendarData(projectTasks);
    }
  }, [projectId, tasks]);

  const onActionBegin = async (args) => {
    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      const taskData = args.data[0];
      const task = new Task({
        gid: taskData.Id,
        name: taskData.Subject,
        due_date_start: taskData.StartTime,
        due_date_end: taskData.EndTime,
        notes: taskData.Description,
        project_id: projectId
      });

      if (args.requestType === 'eventCreate') {
        await addTaskToFirestore(task);
      } else if (args.requestType === 'eventChange') {
        await updateTaskInFirestore(task);
      }
    }
  };

  const addTaskToFirestore = async (task) => {
    try {
      await addDoc(collection(db, 'tasks'), task);
      console.log('Task added to Firestore');
    } catch (error) {
      console.error('Error adding task: ', error);
    }
  };

  const updateTaskInFirestore = async (task) => {
    try {
      const taskDoc = doc(db, 'tasks', task.gid);
      await updateDoc(taskDoc, task);
      console.log('Task updated in Firestore');
    } catch (error) {
      console.error('Error updating task: ', error);
    }
  };

  return (
    <div className='schedule-control-section'>
      <div className='col-lg-12 control-section'>
        <div className='control-wrapper'>
          <ScheduleComponent
            ref={scheduleObj}
            cssClass='schedule-drag-drop'
            width='100%'
            height='650px'
            selectedDate={new Date()}
            currentView='TimelineMonth'
            eventSettings={{
              dataSource: extend([], calendarData, null, true),
              fields: {
                id: 'Id',
                subject: { title: 'Task Name', name: 'Subject' },
                startTime: { title: "Start Time", name: "StartTime" },
                endTime: { title: "End Time", name: "EndTime" },
                description: { title: 'Description', name: 'Description' }
              }
            }}
            actionBegin={onActionBegin}
          >
            <ViewsDirective>
              <ViewDirective option='TimelineDay' />
              <ViewDirective option='TimelineMonth' />
            </ViewsDirective>
            <Inject services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]} />
          </ScheduleComponent>
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
