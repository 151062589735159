import React, { useEffect, useState } from 'react';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import { useFirestore } from '../contexts/FirestoreContext';
import { extend } from '@syncfusion/ej2-base';
import '../styles/App.css'; // Ensure you have some styles for Kanban board

const BoardView = ({ projectId }) => {
  const { tasks } = useFirestore();
  const [kanbanData, setKanbanData] = useState([]);

  useEffect(() => {
    if (projectId) {
      const projectTasks = tasks.filter(task => task.project_id === projectId);
      setKanbanData(extend([], projectTasks, null, true));
    }
  }, [projectId, tasks]);

  return (
    <div className="kanban-control-section">
      <div className="col-lg-12 control-section">
        <div className="control-wrapper">
          <KanbanComponent
            id="kanban"
            keyField="status" // Ensure your task data has a 'status' field for key mapping
            dataSource={kanbanData}
            cardSettings={{
              contentField: "summary", // Ensure your task data has a 'summary' field for content
              headerField: "id" // Ensure your task data has an 'id' field for header
            }}
          >
            <ColumnsDirective>
              <ColumnDirective headerText="To Do" keyField="Open" />
              <ColumnDirective headerText="In Progress" keyField="InProgress" />
              <ColumnDirective headerText="Testing" keyField="Testing" />
              <ColumnDirective headerText="Done" keyField="Close" />
            </ColumnsDirective>
          </KanbanComponent>
        </div>
      </div>
    </div>
  );
};

export default BoardView;
