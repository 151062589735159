import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import ListView from '../views/ListView';
import BoardView from '../views/BoardView'; // Import BoardView
import GanttView from '../views/GanttView'; // Import GanttView
import CalendarView from '../views/CalendarView'; // Import CalendarView
import { useFirestore } from '../contexts/FirestoreContext';

const ProjectPage = () => {
  const { projectId } = useParams();
  const { projects } = useFirestore();
  const selectedProject = projects.find(proj => proj.id === projectId);
  const tabObj = useRef(null);
  let draggedItemHeader;

  useEffect(() => {
    if (tabObj.current) {
      tabObj.current.select(1); // Select the "List" tab once the component is mounted
    }
  }, []);

  const onTabCreate = () => {
    const tabElement = tabObj.current.element;
    if (tabElement) {
      tabElement.querySelector(".e-tab-header").classList.add("e-droppable");
      tabElement.querySelector(".e-content").classList.add("tab-content");
    }
  };

  const onTabDragStart = (args) => {
    draggedItemHeader = tabObj.current.items[args.index].header.text;
  };

  const onDraggedTab = (args) => {
    const tabElement = tabObj.current.element;
    const dragTabIndex = Array.prototype.indexOf.call(tabElement.querySelectorAll('.e-toolbar-item'), args.draggedItem);
    const dropNode = args.target.closest(".e-toolbar-item");

    if (dropNode != null && !args.target.closest("#draggableTab .e-toolbar-item") && tabObj.current.items.length > 1) {
      args.cancel = true;
      const dropContainer = tabElement.querySelectorAll('.e-toolbar-item');
      const dropIndex = Array.prototype.indexOf.call(dropContainer, dropNode);

      const newNode = [{ header: { text: draggedItemHeader }, content: tabObj.current.items[dragTabIndex].content }];
      tabObj.current.removeTab(dragTabIndex);
      tabObj.current.addTab(newNode, dropIndex);
    }
  };

  return (
    <div className="project-page">
      <h1>Project: {selectedProject ? selectedProject.name : 'Loading...'}</h1>
      <TabComponent
        id="draggableTab"
        ref={tabObj}
        created={onTabCreate}
        dragArea="#TabContainer"
        onDragStart={onTabDragStart}
        dragged={onDraggedTab}
        allowDragAndDrop={true}
      >
        <TabItemsDirective>
          <TabItemDirective header={{ text: 'Overview' }} content={() => <div>Overview Content</div>} />
          <TabItemDirective header={{ text: 'List' }} content={() => <ListView projectId={projectId} projects={projects} />} />
          <TabItemDirective header={{ text: 'Board' }} content={() => <BoardView projectId={projectId} />} />
          <TabItemDirective header={{ text: 'Gantt' }} content={() => <GanttView projectId={projectId} />} />
          <TabItemDirective header={{ text: 'Dashboard' }} content={() => <div>Dashboard Content</div>} />
          <TabItemDirective header={{ text: 'Calendar' }} content={() => <CalendarView projectId={projectId} />} />
          <TabItemDirective header={{ text: 'Workflow' }} content={() => <div>Workflow Content</div>} />
          <TabItemDirective header={{ text: 'Messages' }} content={() => <div>Messages Content</div>} />
          <TabItemDirective header={{ text: 'Files' }} content={() => <div>Files Content</div>} />
        </TabItemsDirective>
      </TabComponent>
    </div>
  );
};

export default ProjectPage;
