import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { FirestoreProvider } from './contexts/FirestoreContext';
import SignInPage from './pages/SignInPage';
import ProjectPage from './pages/ProjectPage';
import ListView from './views/ListView';
import HomePage from './pages/HomePage';
import MyTasksPage from './pages/MyTasksPage';
import './styles/App.css';
import PrivateRoute from './components/Auth/PrivateRoute';
import MainLayout from './components/Layout/MainLayout';

function App() {
  return (
    <AuthProvider>
      <FirestoreProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<SignInPage />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<MainLayout><HomePage /></MainLayout>} /> {/* Update Home route to load HomePage */}
              <Route path="/my-tasks" element={<MainLayout><MyTasksPage /></MainLayout>} /> {/* Move ListView to a new path */}
              <Route path="/project/:projectId" element={<MainLayout><ProjectPage /></MainLayout>} />
            </Route>
          </Routes>
        </Router>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;

