import React from 'react';
import { signInWithGoogle } from '../../services/authService';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const GoogleSignIn = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async () => {
    const user = await signInWithGoogle();
    if (user) {
      // Redirect to the dashboard
      navigate('/'); 
    }
  };

  return (
    <div className="login-page">
      <h1>Login</h1>
      <button onClick={handleLogin}>Sign in with Google</button>
    </div>
  );
};

export default GoogleSignIn;