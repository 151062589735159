// src/services/authService.js
import { auth, provider } from './firebase';
import { signInWithPopup, signOut } from 'firebase/auth';

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log('User signed in: ', user);
    return user;
  } catch (error) {
    console.error('Error signing in with Google: ', error);
  }
};

const logOut = async () => {
  try {
    await signOut(auth);
    console.log('User signed out');
  } catch (error) {
    console.error('Error signing out: ', error);
  }
};

export { signInWithGoogle, logOut };