import React, { useState, useEffect, useRef } from 'react';
import { updateDocument, getDocumentsByCondition } from '../../services/firestoreService';
import { useFirestore } from '../../contexts/FirestoreContext';
import useAuth from '../../hooks/useAuth'; 
import firebase from 'firebase/compat/app'; // Import the firebase library
import '../../styles/App.css';

const TaskDetails = ({ task, onClose, projects = [], subtasks = [] }) => { 
  const { currentUser } = useAuth();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');

  useEffect(() => {
    setEditedName(task.name); 
  }, [task]);

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleNameSave = async () => {
    await updateDocument('tasks', task.id, { name: editedName });
    setIsEditingName(false);
  };

  return (
    <div className="task-details" onClick={onClose}>
      <div className="task-details-content">
        {isEditingName ? ( 
          <>
            <input
              type="text"
              value={editedName}
              onChange={handleNameChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleNameSave();
                }
              }}
            />
            <button onClick={handleNameSave}>Save</button>
          </>
        ) : (
          <h2 onClick={() => setIsEditingName(true)} dangerouslySetInnerHTML={{ __html: editedName }} /> 
        )}
        <ul className="task-properties">
          <li>
            <span>Assignee:</span>
            <span>{task.assigned_to}</span> 
          </li>
          <li>
            <span>Due date:</span>
            <span>{task.due_date ? task.due_date : 'No Due Date'}</span> 
          </li>
          <li>
            <span>Projects:</span>
            <select
              multiple
              value={task.projects || []} 
              onChange={(e) => {
                const selectedProjects = Array.from(e.target.selectedOptions).map(option => option.value);
                updateDocument('tasks', task.id, { projects: selectedProjects });
              }}
            >
              {projects.map(project => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </li>
          <li>
            <span>Dependencies:</span>
            <span>{task.dependencies || 'None'}</span> 
          </li>
          <li>
            <span>Fields:</span>
            <div className="fields-grid">
              <table>
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  
                </tbody>
              </table>
            </div>
          </li>
          <li>
            <span>Description:</span>
            <div className="description-box" contentEditable={true} suppressContentEditableWarning={true}>
              {task.description ? (
                <span dangerouslySetInnerHTML={{ __html: task.description }} />
              ) : (
                <span>What is this task about?</span>
              )}
            </div>
          </li>
          
        </ul>
      </div>
    </div>
  );
};

export default TaskDetails;