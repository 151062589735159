import React, { useEffect, useState } from 'react';
import { GanttComponent, Inject, Selection, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-gantt';
import { useFirestore } from '../contexts/FirestoreContext';
import '../styles/App.css'; // Ensure you have some styles for the Gantt chart

const GanttView = ({ projectId }) => {
  const { tasks } = useFirestore();
  const [ganttData, setGanttData] = useState([]);

  useEffect(() => {
    if (projectId && tasks) {
      const projectTasks = tasks
        .filter(task => task.project_id === projectId)
        .map(task => ({
          TaskID: task.id,
          TaskName: task.name,
          StartDate: task.startDate ? new Date(task.startDate) : null,
          EndDate: task.dueOn ? new Date(task.dueOn) : null,
          Duration: task.duration,
          Progress: task.progress,
          Predecessor: task.predecessor,
          subtasks: task.subtasks || []
        }));

      setGanttData(projectTasks);
    }
  }, [projectId, tasks]);

  const taskFields = {
    id: 'TaskID',
    name: 'TaskName',
    startDate: 'StartDate',
    endDate: 'EndDate',
    duration: 'Duration',
    progress: 'Progress',
    dependency: 'Predecessor',
    child: 'subtasks'
  };

  const labelSettings = {
    leftLabel: 'TaskName'
  };

  const projectStartDate = ganttData.length > 0 ? new Date(Math.min(...ganttData.map(task => task.StartDate || new Date()))) : new Date();
  const projectEndDate = ganttData.length > 0 ? new Date(Math.max(...ganttData.map(task => task.EndDate || new Date()))) : new Date();

  return (
    <div className='control-pane'>
      <div className='control-section'>
        <GanttComponent
          id='Default'
          dataSource={ganttData}
          treeColumnIndex={1}
          taskFields={taskFields}
          labelSettings={labelSettings}
          height='410px'
          projectStartDate={projectStartDate}
          projectEndDate={projectEndDate}
        >
          <ColumnsDirective>
            <ColumnDirective field='TaskID' width='80' />
            <ColumnDirective field='TaskName' headerText='Job Name' width='250' clipMode='EllipsisWithTooltip' />
            <ColumnDirective field='StartDate' />
            <ColumnDirective field='Duration' />
            <ColumnDirective field='Progress' />
            <ColumnDirective field='Predecessor' />
          </ColumnsDirective>
          <Inject services={[Selection]} />
        </GanttComponent>
      </div>
    </div>
  );
};

export default GanttView;
