// src/components/Tasks/AddTask.js
import React, { useState } from 'react';
import { addDocument } from '../../services/firestoreService';
import useAuth from '../../hooks/useAuth';
import Task from '../../models/Task';

const AddTask = ({ projectId, gridTasks, onTaskAdded }) => {
  const { currentUser } = useAuth();
  const [newTask, setNewTask] = useState(new Task());
  const [isAddingTask, setIsAddingTask] = useState(false); // State to manage form visibility

  const handleAddTask = async () => {
    const nextPosition = gridTasks.length; // Get the next available position
    const taskData = {
      ...newTask,
      project_id: projectId,
      created_by: currentUser.uid,
      created_at: new Date(),
      updated_at: new Date(),
      position: nextPosition, // Assign the new position
    };
    const taskId = await addDocument(`tasks`, taskData);
    setNewTask(new Task());
    setIsAddingTask(false); // Hide the form after adding the task
    onTaskAdded(prevTasks => [...prevTasks, { id: taskId, ...taskData, position: nextPosition }]);
  };

  return (
    <div>
      {!isAddingTask && <button onClick={() => setIsAddingTask(true)}>Add Task...</button>}
      {isAddingTask && (
        <div>
          <h3>New Task</h3>
          <input
            type="text"
            placeholder="Task Name"
            value={newTask.name}
            onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={newTask.description}
            onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
          />
          <input
            type="text"
            placeholder="Status"
            value={newTask.assignee_status}
            onChange={(e) => setNewTask({ ...newTask, assignee_status: e.target.value })}
          />
          <input
            type="text"
            placeholder="HTML Notes"
            value={newTask.html_notes}
            onChange={(e) => setNewTask({ ...newTask, html_notes: e.target.value })}
          />
          <input
            type="text"
            placeholder="Tags"
            value={newTask.tags.join(',')}
            onChange={(e) => setNewTask({ ...newTask, tags: e.target.value.split(',') })}
          />
          <button onClick={handleAddTask}>Add Task</button>
          <button onClick={() => setIsAddingTask(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default AddTask;
