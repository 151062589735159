import React, { useState, useEffect, useRef } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import TaskTreeGrid from '../components/Tasks/TaskTreeGrid'; // Updated import
import '../styles/App.css';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';
import AddTask from '../components/Tasks/AddTask';
import TaskDetails from '../components/Tasks/TaskDetails'; // Import TaskDetails

const ListView = ({ projectId, projects }) => {  // Ensure the component receives projects prop
  const { tasks, subtasks } = useFirestore();
  const [selectedProject, setSelectedProject] = useState(null);
  const [taskData, setTaskData] = useState([]);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const taskDetailsRef = useRef(null);

  useEffect(() => {
    if (projectId) {
      const project = projects.find(proj => proj.id === projectId);
      setSelectedProject(project);
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (selectedProject) {
      setTaskData(tasks.filter((task) => task.project_id === selectedProject.id));
    }
  }, [tasks, selectedProject]);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    taskDetailsRef.current.classList.add('show');
  };

  const handleTaskDetailsClose = () => {
    setSelectedTask(null);
    taskDetailsRef.current.classList.remove('show');
  };

  return (
    <div className="listview">
      {selectedProject && (
        <>
          <div className="task-details-wrapper">
            {/* Add the wrapper div */}
            <div className="task-grid">
              <TaskTreeGrid selectedProjectId={selectedProject.id} /> {/* Updated component */}
            </div>
            <div className="task-details" ref={taskDetailsRef} onClick={handleTaskDetailsClose}>
              {selectedTask && (
                <TaskDetails
                  task={selectedTask}
                  onClose={handleTaskDetailsClose}
                  projects={projects}
                  subtasks={subtasks}
                />
              )}
            </div>
          </div>
          <button className="add-task-button" onClick={() => setIsAddingTask(true)}>
            Add a task...
          </button>
          {isAddingTask && (
            <AddTask projectId={selectedProject.id} gridTasks={taskData} onTaskAdded={() => setIsAddingTask(false)} />
          )}
        </>
      )}
    </div>
  );
};

export default ListView;  // Ensure ListView is exported
