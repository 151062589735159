import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Layout/Sidebar'; // Adjust the import path as necessary
import '../../styles/App.css';
import useAuth from '../../hooks/useAuth';
import { useFirestore } from '../../contexts/FirestoreContext';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import '../../styles/material3-dark.css';

const MainLayout = ({ children }) => {
  const { projects } = useFirestore();
  const { currentUser } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const addProject = async (newProject) => {
    await addDoc(collection(db, 'users', currentUser.uid, 'projects'), newProject);
  };

  const handleProjectSelect = (project) => {
    navigate(`/project/${project.id}`);
  };

  return (
    <div className="main-layout">
      <div className="header">
        <button className="menu-button" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          ☰
        </button>
        <div className="search-bar">
          <input type="text" placeholder="Search..." />
        </div>
        <div className="user-info">
          <span>{currentUser?.displayName}</span>
          <button
            onClick={() => {
              localStorage.removeItem('uid'); // Remove the uid from local storage
              window.location.href = '/login'; // Redirect to the login page
            }}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="main">
        <Sidebar
          isOpen={isSidebarOpen}
          projects={projects}
          addProject={addProject}
          onProjectSelect={handleProjectSelect}
        />
        <div className={`main-content ${isSidebarOpen ? 'with-sidebar' : 'collapsed'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
