// src/contexts/FirestoreContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';

const FirestoreContext = createContext();

export const useFirestore = () => useContext(FirestoreContext);

export const FirestoreProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [firestore, setFirestore] = useState(db);

  useEffect(() => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      setDoc(userDocRef, { uid: currentUser.uid }, { merge: true });

      const unsubscribeProjects = onSnapshot(
        collection(db, 'users', currentUser.uid, 'projects'),
        (snapshot) => {
          const projectsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProjects(projectsData);
        }
      );

      const unsubscribeTasks = onSnapshot(
        collection(db, 'users', currentUser.uid, 'tasks'),
        (snapshot) => {
          const tasksData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTasks(tasksData);
        }
      );

      const unsubscribeSubtasks = onSnapshot(
        collection(db, 'users', currentUser.uid, 'subtasks'),
        (snapshot) => {
          const subtasksData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSubtasks(subtasksData);
        }
      );

      return () => {
        unsubscribeProjects();
        unsubscribeTasks();
        unsubscribeSubtasks();
      };
    }
  }, [currentUser]);

  return (
    <FirestoreContext.Provider value={{ projects, tasks, subtasks, firestore }}>
      {children}
    </FirestoreContext.Provider>
  );
};

export { FirestoreContext };
