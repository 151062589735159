import React, { useContext, useEffect, useState } from 'react';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Sort, Filter, Reorder, Edit, Freeze, RowDD, Selection } from '@syncfusion/ej2-react-treegrid';
import { FirestoreContext } from '../../contexts/FirestoreContext';
import { useAuth } from '../../contexts/AuthContext';
import { collection, query, where, onSnapshot, orderBy, doc, updateDoc } from 'firebase/firestore';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import '../../styles/App.css';

const TaskTreeGrid = ({ selectedProjectId }) => {
  const { firestore } = useContext(FirestoreContext);
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedProjectId && firestore && currentUser) {
      setLoading(true); // Start loading
      const q = query(
        collection(firestore, 'users', currentUser.uid, 'tasks'),
        where('project_id', '==', selectedProjectId),
        orderBy('position')
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tasksData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          tasksData.push({ id: doc.id, ...data });
        });
        // Ensure all boolean fields are initialized
        const initializedTasks = tasksData.map(task => ({
          ...task,
          completed: task.completed ?? false,
          hearted: task.hearted ?? false,
          is_rendered_as_separator: task.is_rendered_as_separator ?? false,
          liked: task.liked ?? false,
          done: task.done ?? false,
        }));
        setTasks(initializedTasks);
        setLoading(false); // Stop loading
      }, (error) => {
        console.error("Error fetching tasks: ", error);
        setLoading(false); // Stop loading on error
      });
      return () => {
        setTasks([]); // Clear tasks on project change
        unsubscribe();
      };
    } else {
      setTasks([]);
    }
  }, [firestore, selectedProjectId, currentUser]);

  const handleBooleanChange = async (field, value, rowData) => {
    const taskDocRef = doc(firestore, 'users', currentUser.uid, 'tasks', rowData.id);
    await updateDoc(taskDocRef, { [field]: value });
  };

  const handleDateRangeChange = async (rowData) => {
    const taskDocRef = doc(firestore, 'users', currentUser.uid, 'tasks', rowData.id);
    await updateDoc(taskDocRef, { due_date_start: rowData.due_date_start, due_date_end: rowData.due_date_end });
  };

  const booleanTemplate = (props) => {
    const value = props[props.column.field] ?? false;
    return (
      <CheckBoxComponent
        checked={value}
        change={async (e) => {
          await handleBooleanChange(props.column.field, e.checked, props);
        }}
      />
    );
  };

  const booleanEditTemplate = (props) => {
    const value = props.rowData?.[props.column.field] ?? false;
    return (
      <CheckBoxComponent
        checked={value}
        change={(e) => {
          if (props.rowData) {
            props.rowData[props.column.field] = e.checked;
          }
        }}
      />
    );
  };

  const dateRangeTemplate = (props) => {
    if (!props) return null;
    const startTimestamp = props.due_date_start;
    const endTimestamp = props.due_date_end;
    const startDate = startTimestamp ? startTimestamp.toDate() : null;
    const endDate = endTimestamp ? endTimestamp.toDate() : null;
    return <DateRangePickerComponent startDate={startDate} endDate={endDate} />;
  };

  const dateRangeEditTemplate = (props) => {
    if (!props || !props.rowData) return null;
    const startTimestamp = props.rowData.due_date_start;
    const endTimestamp = props.rowData.due_date_end;
    const startDate = startTimestamp ? startTimestamp.toDate() : null;
    const endDate = endTimestamp ? endTimestamp.toDate() : null;
    return (
      <DateRangePickerComponent
        startDate={startDate}
        endDate={endDate}
        change={(e) => {
          if (props.rowData) {
            props.rowData.due_date_start = e.value[0] ? { seconds: Math.floor(e.value[0].getTime() / 1000), nanoseconds: 0 } : null;
            props.rowData.due_date_end = e.value[1] ? { seconds: Math.floor(e.value[1].getTime() / 1000), nanoseconds: 0 } : null;
            handleDateRangeChange(props.rowData);
          }
        }}
      />
    );
  };

  const editOptions = {
    allowAdding: false,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Normal',
  };

  const validationRules = {
    name: { required: true },
    assignee: { required: true },
    due_date: { required: true }
  };

  // Custom FormValidator override
  const customFormValidator = (formValidator) => {
    const originalGetErrorElement = formValidator.getErrorElement.bind(formValidator);
    formValidator.getErrorElement = (name) => {
      const element = originalGetErrorElement(name);
      return element && element.parentElement ? element : null;
    };
  };

  useEffect(() => {
    // Override FormValidator method when the component mounts
    const formValidators = document.querySelectorAll('.e-formvalidator');
    formValidators.forEach((formValidator) => {
      customFormValidator(formValidator.ej2_instances[0]);
    });
  }, []);

  return (
    <div className="control-pane" role="control" aria-label="Tree Grid Control">
      <div className="control-section">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <TreeGridComponent
            dataSource={tasks}
            childMapping="subtasks"
            height="400"
            allowReordering={true}
            allowFiltering={true}
            allowSorting={true}
            allowRowDragAndDrop={true}
            selectionSettings={{ type: "Multiple" }}
            editSettings={editOptions}
            filterSettings={{ type: "Menu", hierarchyMode: "Parent" }}
            key={selectedProjectId} // Ensure key changes on project switch
          >
            <ColumnsDirective>
              <ColumnDirective field="name" headerText="Name" width="210" textAlign="Left" isFrozen={true} validationRules={validationRules.name} />
              <ColumnDirective field="assignee" headerText="Assignee" width="150" textAlign="Left" validationRules={validationRules.assignee} />
              <ColumnDirective field="due_date" headerText="Due date" width="150" textAlign="Left" template={dateRangeTemplate} editTemplate={dateRangeEditTemplate} validationRules={validationRules.due_date} />
              <ColumnDirective field="id" headerText="ID" width="150" textAlign="Left" isPrimaryKey={true} />
              <ColumnDirective field="gid" headerText="GID" width="150" textAlign="Left" />
              <ColumnDirective field="approval_status" headerText="Approval Status" width="150" textAlign="Left" />
              <ColumnDirective field="assignee_status" headerText="Assignee Status" width="150" textAlign="Left" />
              <ColumnDirective field="completed" headerText="Completed" width="150" textAlign="Left" template={booleanTemplate} editTemplate={booleanEditTemplate} />
              <ColumnDirective field="completed_at" headerText="Completed At" width="150" textAlign="Left" />
              <ColumnDirective field="completed_by" headerText="Completed By" width="150" textAlign="Left" />
              <ColumnDirective field="created_at" headerText="Created At" width="150" textAlign="Left" />
              <ColumnDirective field="created_by" headerText="Created By" width="150" textAlign="Left" />
              <ColumnDirective field="custom_fields" headerText="Custom Fields" width="150" textAlign="Left" />
              <ColumnDirective field="dependencies" headerText="Dependencies" width="150" textAlign="Left" />
              <ColumnDirective field="dependents" headerText="Dependents" width="150" textAlign="Left" />
              <ColumnDirective field="due_at" headerText="Due At" width="150" textAlign="Left" />
              <ColumnDirective field="external" headerText="External" width="150" textAlign="Left" />
              <ColumnDirective field="followers" headerText="Followers" width="150" textAlign="Left" />
              <ColumnDirective field="html_notes" headerText="HTML Notes" width="150" textAlign="Left" />
              <ColumnDirective field="hearted" headerText="Hearted" width="150" textAlign="Left" template={booleanTemplate} editTemplate={booleanEditTemplate} />
              <ColumnDirective field="hearts" headerText="Hearts" width="150" textAlign="Left" />
              <ColumnDirective field="is_rendered_as_separator" headerText="Rendered as Separator" width="150" textAlign="Left" template={booleanTemplate} editTemplate={booleanEditTemplate} />
              <ColumnDirective field="liked" headerText="Liked" width="150" textAlign="Left" template={booleanTemplate} editTemplate={booleanEditTemplate} />
              <ColumnDirective field="likes" headerText="Likes" width="150" textAlign="Left" />
              <ColumnDirective field="memberships" headerText="Memberships" width="150" textAlign="Left" />
              <ColumnDirective field="modified_at" headerText="Modified At" width="150" textAlign="Left" />
              <ColumnDirective field="notes" headerText="Notes" width="150" textAlign="Left" />
              <ColumnDirective field="num_hearts" headerText="Number of Hearts" width="150" textAlign="Left" />
              <ColumnDirective field="num_likes" headerText="Number of Likes" width="150" textAlign="Left" />
              <ColumnDirective field="parent" headerText="Parent" width="150" textAlign="Left" />
              <ColumnDirective field="permalink_url" headerText="Permalink URL" width="150" textAlign="Left" />
              <ColumnDirective field="projects" headerText="Projects" width="150" textAlign="Left" />
              <ColumnDirective field="resource_subtype" headerText="Resource Subtype" width="150" textAlign="Left" />
              <ColumnDirective field="resource_type" headerText="Resource Type" width="150" textAlign="Left" />
              <ColumnDirective field="start_on" headerText="Start On" width="150" textAlign="Left" />
              <ColumnDirective field="tags" headerText="Tags" width="150" textAlign="Left" />
              <ColumnDirective field="workspace" headerText="Workspace" width="150" textAlign="Left" />
              <ColumnDirective field="done" headerText="Done" width="150" textAlign="Left" template={booleanTemplate} editTemplate={booleanEditTemplate} />
              <ColumnDirective field="project_id" headerText="Project ID" width="150" textAlign="Left" />
              <ColumnDirective field="assigned_to" headerText="Assigned To" width="150" textAlign="Left" />
            </ColumnsDirective>
            <Inject services={[Filter, Sort, Reorder, Edit, Page, Freeze, RowDD, Selection]} />
          </TreeGridComponent>
        )}
      </div>
    </div>
  );
};

export default TaskTreeGrid;
