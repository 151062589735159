import React from 'react';
import useAuth from '../hooks/useAuth';
import { DashboardLayoutComponent, PanelDirective, PanelsDirective } from '@syncfusion/ej2-react-layouts';
import { TextAreaComponent } from '@syncfusion/ej2-react-inputs';
import '../styles/App.css';

const HomePage = () => {
  const { currentUser } = useAuth();
  const username = currentUser?.displayName?.split(' ')[0] || "user";
  const now = new Date();

  // Get current date details
  const dayOfWeek = now.toLocaleString('default', { weekday: 'long' });
  const month = now.toLocaleString('default', { month: 'long' });
  const day = now.getDate();
  const daySuffix = getDaySuffix(day);

  // Get greeting based on the current hour
  const hour = now.getHours();
  const greeting = hour < 12 ? "morning" : hour < 18 ? "afternoon" : "evening";

  return (
    <div style={styles.pageContainer}>
      <div style={styles.container}>
        <h2 style={styles.date}>{`${dayOfWeek}, ${month} ${day}${daySuffix}`}</h2>
        <h1 style={styles.greeting}>Good {greeting}, {username}</h1>
        <div style={styles.widgetPill}>
          <div style={styles.widgetItem}>
            <p style={styles.widgetInteger}>42</p>
            <p style={styles.widgetTitle}>Tasks Completed</p>
          </div>
          <div style={styles.widgetItem}>
            <p style={styles.widgetInteger}>7</p>
            <p style={styles.widgetTitle}>Collaborators</p>
          </div>
        </div>
        <div style={styles.dashboardContainer}>
          <DashboardLayoutComponent
            id="dashboard"
            cellSpacing={[10, 10]} // Reduced cellSpacing
            columns={2} // Increased columns to maintain layout after size reduction
          >
            <PanelsDirective>
              <PanelDirective sizeX={2} sizeY={1} row={0} col={0} header="<div>My tasks</div>" content={MyTasks} cssClass="custom-panel"></PanelDirective>
              <PanelDirective sizeX={1} sizeY={1} row={1} col={0} header="<div>Projects</div>" content={Projects} cssClass="custom-panel"></PanelDirective>
              <PanelDirective sizeX={1} sizeY={1} row={1} col={1} header="<div>Private notepad</div>" content={PrivateNotepad} cssClass="custom-panel"></PanelDirective>
              <PanelDirective sizeX={1} sizeY={1} row={2} col={0} header="<div>People</div>" content={People} cssClass="custom-panel"></PanelDirective>
              <PanelDirective sizeX={1} sizeY={1} row={2} col={1} header="<div>Team workload</div>" content={TeamWorkload} cssClass="custom-panel"></PanelDirective>
            </PanelsDirective>
          </DashboardLayoutComponent>
        </div>
      </div>
    </div>
  );
};

const MyTasks = () => {
  return (
    <div>
      <ul>
        <li>Task 1</li>
        <li>Task 2</li>
        <li>Task 3</li>
      </ul>
    </div>
  );
};

const Projects = () => {
  return (
    <div>
      {/* Default content for Projects */}
      <ul>
        <li>Project 1</li>
        <li>Project 2</li>
        <li>Project 3</li>
      </ul>
    </div>
  );
};

const PrivateNotepad = () => {
  return (
    <div>
      <TextAreaComponent
        id="private-notepad"
        placeholder="Write your notes here..."
        rows={3}
        cols={20}
      />
    </div>
  );
};

const People = () => {
  return (
    <div>
      <ul>
        <li>User 1</li>
        <li>User 2</li>
        <li>User 3</li>
      </ul>
    </div>
  );
};

const TeamWorkload = () => {
  return (
    <div>
      <p>Sample data here...</p>
    </div>
  );
};

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: "'Proxima Nova', Arial, sans-serif",
    height: '100vh',
    overflowY: 'auto',
    paddingLeft: '200px',
    paddingRight: '200px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  date: {
    marginTop: '20px',
    fontSize: '18px',
    textAlign: 'center',
  },
  greeting: {
    marginTop: '10px',
    fontSize: '28px',
    textAlign: 'center',
  },
  widgetPill: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: '100px',
    padding: '10px 20px',
    marginTop: '20px',
    width: '300px',
    height: '50px',
  },
  widgetItem: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 10px',
  },
  widgetInteger: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '5px',
  },
  widgetTitle: {
    fontSize: '12px',
    fontWeight: 'lighter',
  },
  dashboardContainer: {
    marginTop: '20px', // Added marginTop to add space between the pill widget and the dashboard
    width: '100%',
  },
};

export default HomePage;
