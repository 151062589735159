import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineHome, AiOutlineCheck, AiOutlineBell, AiOutlinePlus, AiOutlineBarChart, AiOutlineFolder, AiOutlineAim } from 'react-icons/ai';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import useAuth from '../../hooks/useAuth';
import { useFirestore } from '../../contexts/FirestoreContext';

const Sidebar = ({ isOpen, projects = [], addProject, onProjectSelect }) => {
  const { currentUser } = useAuth(); 
  const [showInsights, setShowInsights] = useState(true);
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [isAddingProject, setIsAddingProject] = useState(false);
  const navigate = useNavigate();

  const handleAddProject = () => {
    addProject({ 
      name: newProjectName,
      description: newProjectDescription,
      status: 'Active',
      created_by: currentUser.uid, 
      created_at: new Date(),
      updated_at: new Date(),
    });
    setNewProjectName('');
    setNewProjectDescription('');
    setIsAddingProject(false);
  };

  return (
    <DragDropContext onDragEnd={() => {}}>
      <div className={`sidebar ${isOpen ? '' : 'hide'}`}>
        <div className="sidebar-link" onClick={() => navigate('/')}> {/* Navigate to home page */}
          <AiOutlineHome /> Home
        </div>
        <div className="sidebar-link" onClick={() => navigate('/my-tasks')}> {/* Navigate to tasks page */}
          <AiOutlineCheck /> My tasks
        </div>
        <div className="sidebar-link">
          <AiOutlineBell /> Inbox
        </div>
        <hr />
        <div className="sidebar-section">
          <div className="sidebar-section-header" onClick={() => setShowInsights(!showInsights)}>
            Insights <AiOutlinePlus />
          </div>
          {showInsights && (
            <div className="sidebar-section-list">
              <div className="sidebar-link">
                <AiOutlineBarChart /> Reporting
              </div>
              <div className="sidebar-link">
                <AiOutlineFolder /> Portfolios
              </div>
              <div className="sidebar-link">
                <AiOutlineAim /> Goals
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="sidebar-section">
          <div className="sidebar-section-header">
            Projects <button onClick={() => setIsAddingProject(true)}><AiOutlinePlus /></button>
          </div>
          {isAddingProject && (
            <div>
              <input
                type="text"
                placeholder="Project Name"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
              <textarea
                placeholder="Project Description"
                value={newProjectDescription}
                onChange={(e) => setNewProjectDescription(e.target.value)}
              />
              <button onClick={handleAddProject}>Add Project</button>
              <button onClick={() => setIsAddingProject(false)}>Cancel</button>
            </div>
          )}
          <Droppable droppableId="projects">
            {(provided) => (
              <div className="sidebar-section-list" ref={provided.innerRef} {...provided.droppableProps}>
                 {projects.map((project, index) => (
                   <Draggable key={project.id} draggableId={project.id} index={index}>
                     {(provided) => (
                       <div
                         className="sidebar-link"
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         onClick={() => onProjectSelect(project)}
                       >
                         {project.name}
                       </div>
                     )}
                   </Draggable>
                 ))}
                 {provided.placeholder}
               </div>
             )}
           </Droppable>
         </div>
       </div>
     </DragDropContext>
   );
 };
 
 export default Sidebar;
