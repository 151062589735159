// src/pages/SignInPage.js
import React from 'react';
import GoogleSignIn from '../components/Auth/GoogleSignIn';

const SignInPage = () => {
  return (
    <div className="sign-in-page">
      <h1>Sign In Page</h1>
      <GoogleSignIn />
    </div>
  );
};

export default SignInPage;
