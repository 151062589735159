// src/services/firestoreService.js
import { db } from './firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const getCurrentUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.uid : null;
};

// Generic function to add a document to a collection
export const addDocument = async (collectionName, data) => {
  const userId = getCurrentUserId();
  if (!userId) throw new Error('User not authenticated');
  const docRef = await addDoc(collection(db, `users/${userId}/${collectionName}`), data);
  return docRef.id;
};

// Generic function to update a document
export const updateDocument = async (collectionName, id, data) => {
  const userId = getCurrentUserId();
  const docRef = doc(db, `users/${userId}/${collectionName}`, id);
  await updateDoc(docRef, data);
};

// Generic function to delete a document
export const deleteDocument = async (collectionName, id) => {
  const userId = getCurrentUserId();
  const docRef = doc(db, `users/${userId}/${collectionName}`, id);
  await deleteDoc(docRef);
};

// Function to get all documents from a collection
export const getAllDocuments = async (collectionName) => {
  const userId = getCurrentUserId();
  const querySnapshot = await getDocs(collection(db, `users/${userId}/${collectionName}`));
  const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return data;
};

// Function to get documents based on a condition
export const getDocumentsByCondition = async (collectionName, field, condition, value) => {
  const userId = getCurrentUserId();
  const q = query(collection(db, `users/${userId}/${collectionName}`), where(field, condition, value));
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return data;
};

// Specific function to get subtasks by task ID
export const getSubtasksByTaskId = async (taskId) => {
  const userId = getCurrentUserId();
  return await getDocumentsByCondition(`users/${userId}/subtasks`, 'task_id', '==', taskId);
};
